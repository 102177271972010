<!-- 互动有礼/礼品列表 -->
<template>
  <div class="vhc-container">
    <!-- 顶部搜索栏 -->
    <div class="vhc-search-bar" v-if="!isEmpty" style="margin-top: 20px">
      <vh-button size="medium" type="primary" round @click="handleAdd">创建礼品</vh-button>
      <vh-button
        size="medium"
        round
        plain
        type="default"
        class="transparent-btn"
        @click="handleMeterial"
      >
        资料库
      </vh-button>

      <vh-button
        size="medium"
        round
        plain
        @click="handleDelete()"
        :disabled="multipleSelection.length === 0"
      >
        批量删除
      </vh-button>
      <vh-input
        round
        class="search-input"
        placeholder="请输入礼品标题"
        v-model="keyword"
        clearable
        v-clearEmoij
        @clear="handleSearch"
        @keyup.enter.native="handleSearch"
      >
        <i class="vh-icon-search vh-input__icon" slot="prefix" @click="handleSearch"></i>
      </vh-input>
    </div>

    <!-- 内容区 -->
    <div class="vhc-container__bd" v-loading="!isInited && loading">
      <div class="vhc-main" v-if="isInited">
        <!-- 没有数据时的展示 -->
        <div class="no-content" v-if="isEmpty">
          <noData :nullType="'nullData'" :text="'您还没有礼品，快来创建吧~'">
            <vh-button
              type="primary"
              round
              borderRadius="50"
              size="medium"
              style="width: 152px"
              v-preventReClick
              @click.prevent.stop="handleAdd"
            >
              创建礼品
            </vh-button>
            <vh-button
              round
              plain
              type="default"
              size="medium"
              borderRadius="50"
              style="width: 152px"
              v-preventReClick
              @click="handleMeterial"
            >
              资料库
            </vh-button>
          </noData>
        </div>
        <!-- 数据列表 -->
        <div class="vhc-main-content" v-if="!isEmpty">
          <vh-table
            ref="tableList"
            id="prizeListTable"
            :data="dataList"
            v-loading="loading"
            class="vhc-main-table"
            @selection-change="handleSelectionChange"
            @cell-mouse-enter="handleCellMouseEnter"
            @cell-mouse-leave="handleCellMouseLeave"
          >
            <vh-table-column type="selection" width="60" fixed="left" />
            <vh-table-column prop="img_path" label="礼品图片" width="100">
              <template slot-scope="scope">
                <div class="image-preview">
                  <vh-image
                    style="height: 60px"
                    :src="scope.row.img_path"
                    :preview-src-list="[scope.row.img_path]"
                  ></vh-image>
                </div>
              </template>
            </vh-table-column>
            <vh-table-column prop="prize_name" label="礼品名称" width="140">
              <template slot-scope="scope">
                <vh-tooltip
                  :disabled="!isTextOverflow"
                  effect="dark"
                  placement="top-start"
                  :content="scope.row.prize_name"
                >
                  <div class="custom-tooltip-content">{{ scope.row.prize_name }}</div>
                </vh-tooltip>
              </template>
            </vh-table-column>
            <vh-table-column prop="prize_desc" label="礼品描述">
              <template slot-scope="scope">
                <vh-tooltip
                  :disabled="!isTextOverflow"
                  effect="dark"
                  placement="top-start"
                  :content="scope.row.prize_desc"
                >
                  <div class="custom-tooltip-content">{{ scope.row.prize_desc }}</div>
                </vh-tooltip>
              </template>
            </vh-table-column>
            <vh-table-column prop="create_time" label="创建时间" width="160">
              <template slot-scope="scope">
                <span>{{ scope.row.create_time | dateFormat('YYYY-MM-DD HH:mm') }}</span>
              </template>
            </vh-table-column>
            <vh-table-column label="操作" width="160">
              <template slot-scope="scope">
                <vh-button type="text" size="tiny" @click="handleCopy(scope.row)">复制</vh-button>
                <vh-button
                  type="text"
                  size="tiny"
                  :disabled="scope.row.recipients_number > 0"
                  @click="handleEdit(scope.row)"
                >
                  编辑
                </vh-button>
                <vh-button
                  type="text"
                  size="tiny"
                  :disabled="scope.row.recipients_number > 0"
                  @click="handleDelete(scope.row)"
                >
                  删除
                </vh-button>
              </template>
            </vh-table-column>
          </vh-table>
          <SPagination
            :total="total || 0"
            :page-size="pagination.limit"
            :currentPage="pagination.pageNo"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></SPagination>
        </div>
      </div>
    </div>

    <!-- 创建/编辑礼品对话框 -->
    <AddOrEditReward
      ref="setPrize"
      :roomId="roomId"
      :webinarId="webinarId"
      @completeEdit="completeEdit"
      @completeAdd="completeAdd"
    ></AddOrEditReward>

    <!-- 礼品选择对话框 -->
    <ChooseReward
      ref="ChooseReward"
      :source="1"
      :maxCount="200"
      @before-add="beforeRewardAdd"
      @completeSelection="completeRewardSelection"
    ></ChooseReward>
  </div>
</template>
<script>
  import noData from '@/views/PlatformModule/Error/nullPage';
  import to from 'await-to-js';
  import { sessionOrLocal, isEmbed, embedRoomId } from '@/utils/utils';
  import AddOrEditReward from './dialog/AddOrEditReward.vue';
  import ChooseReward from './dialog/ChooseReward.vue';
  import tableCellTooltip from '@/components/TableList/mixins/tableCellTooltip';
  import { debounce } from '@/utils/utils';

  export default {
    name: 'InteractiveRewardList',
    mixins: [tableCellTooltip],
    components: {
      noData,
      AddOrEditReward,
      ChooseReward
    },
    data() {
      return {
        isEmbed: isEmbed(),
        webinarId: '', //活动id
        roomId: '', //房间id

        isInited: false, // 是否有成功的请求
        loading: false,
        isEmpty: true, // 是否完全空数据

        keyword: '', // 搜索关键字
        total: 0, // 总数量
        dataList: [], //数据集合
        multipleSelection: [], //选中的数据集合
        // 分页参数
        pagination: {
          pos: 0,
          limit: 10,
          pageNo: 1
        }
      };
    },
    methods: {
      // 创建
      handleAdd() {
        console.log('------handleAdd----');
        this.$refs.setPrize.reset();
        this.$refs.setPrize.dialogVisible = true;
      },
      // 资料库
      handleMeterial() {
        console.log('------handleMeterial----');
        this.$refs.ChooseReward.dialogVisible = true;
      },
      // 可选资源为空时，点击【创建】
      beforeRewardAdd() {
        // 延迟打开，避免上个对话框关闭时闪烁
        setTimeout(() => {
          this.$refs.setPrize.reset();
          this.$refs.setPrize.dialogVisible = true;
        }, 300);
      },
      // 完成礼品选择
      async completeRewardSelection(prizes = []) {
        const ids = prizes.map(item => item.prize_id).join(',');
        const params = {
          webinar_id: this.webinarId,
          room_id: this.roomId,
          source: 0, // 直播间
          award_type: 2, // 固定为礼品
          prize_id: ids
        };
        const [err, res] = await to(this.$fetch('copyPrize', params));
        if (err || res?.code != 200) {
          this.$message.error(err?.msg || res?.msg || '操作失败');
          return;
        }
        this.$vhMessage.success('选择成功');
        this.$refs.ChooseReward.dialogVisible = false;
        this.handleSearch();
      },
      // 复制某一条
      handleCopy(row) {
        debounce(async () => {
          const params = {
            webinar_id: this.webinarId,
            room_id: this.roomId,
            source: 0, // 直播间
            award_type: 2, // 固定为礼品
            prize_id: row.prize_id
          };
          const [err, res] = await to(this.$fetch('copyPrize', params));
          if (err || res?.code != 200) {
            this.$message.error(err?.msg || res?.msg || '操作失败');
            return;
          }
          this.$vhMessage.success('复制成功');
          this.handleSearch();
        }, 500);
      },
      // 编辑某一条
      handleEdit(row) {
        console.log('------handleEdit--:', row);
        const ent = {
          ...row
        };
        this.$refs.setPrize.reInit(ent);
        this.$refs.setPrize.dialogVisible = true;
      },
      // 删除某一条/批量删除
      async handleDelete(row) {
        const confirm = await this.$vhConfirm(
          '删除后，当前礼品无法使用和查看，确认删除？',
          '提示',
          {
            roundButton: true,
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            customClass: 'zdy-message-box',
            lockScroll: false,

            closeOnClickModal: false,
            closeOnPressEscape: false,
            cancelButtonClass: 'zdy-confirm-cancel'
          }
        ).catch(() => false);
        if (!confirm) return;
        const params = {
          source: 0,
          award_type: 2, // 固定为礼品
          webinar_id: this.webinarId,
          room_id: this.roomId,
          prize_id: row
            ? row.prize_id
            : (this.multipleSelection.map(item => item.prize_id) || []).join(',')
        };
        const [err, res] = await to(this.$fetch('delPrize', params));
        if (err || res?.code != 200) {
          this.$vhMessage.error(err?.msg || res?.msg || '请求失败');
          return;
        }
        this.$vhMessage.success('删除成功');
        this.handleSearch();
      },
      // 编辑礼品完成
      completeEdit() {
        this.fetchData();
      },
      // 添加礼品完成
      completeAdd() {
        this.$vhMessage.success('创建成功');
        this.fetchData();
      },
      // 搜索
      handleSearch() {
        this.pagination.pos = 0;
        this.fetchData();
      },
      // 全选/部分选中/取消/部分取消时触发
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      // 翻页事件
      handleCurrentChange(current) {
        this.pagination.pageNo = current; //当前页码
        this.pagination.pos = parseInt((current - 1) * this.pagination.limit);
        this.fetchData();
      },
      // 每页条数改变时触发
      handleSizeChange(limit) {
        this.pagination.limit = limit;
        this.pagination.pageNo = 1;
        this.pagination.pos = 0;
        this.fetchData();
      },
      // 请求数据
      fetchData: async function () {
        this.loading = true;
        this.keyword = this.keyword.trim();
        const params = {
          award_type: 2, // 固定2表示礼品
          keyword: this.keyword,
          source: 0, // 0:直播间(默认) 1:资料管理 当source为0时room_id必填,当source为1时room_id必不填
          limit: this.pagination.limit,
          pos: this.pagination.pos,
          room_id: this.roomId
        };
        const [err, res] = await to(this.$fetch('getPrizeList', params));
        this.loading = false;
        if (err || res?.code != 200) {
          this.$message.error(err?.msg || res?.msg || '请求数据失败');
          return;
        }
        this.isInited = true;
        this.total = res.data.count || 0;
        this.isEmpty = !this.total && this.keyword.length === 0;
        this.dataList = res.data.list;
      }
    },
    mounted() {
      this.webinarId = this.$route.params.str || '';
      this.roomId = this.$route.query.roomId || '';
      this.fetchData();
    }
  };
</script>
