<!-- 任务列表 -->
<template>
  <div class="vhc-container">
    <!-- 顶部搜索栏 -->
    <div class="vhc-search-bar" v-if="!isEmpty" style="margin-top: 20px">
      <vh-button size="medium" type="primary" round @click="handleAdd">创建</vh-button>
      <vh-button
        size="medium"
        round
        plain
        @click="handleDelete()"
        :disabled="!multipleSelection.length"
      >
        批量删除
      </vh-button>
      <vh-input
        round
        class="search-input"
        placeholder="请输入标题"
        v-model="keyword"
        clearable
        v-clearEmoij
        @clear="handleSearch"
        @keyup.enter.native="handleSearch"
      >
        <i class="vh-icon-search vh-input__icon" slot="prefix" @click="handleSearch"></i>
      </vh-input>
    </div>

    <!-- 内容区 -->
    <div class="vhc-container__bd" v-loading="!isInited && loading">
      <div class="vhc-main" v-if="isInited">
        <!-- 没有数据时的展示 -->
        <div class="no-content" v-if="isEmpty">
          <noData :nullType="'nullData'" :text="'您还没有内容，快来创建吧~'">
            <vh-button
              type="primary"
              round
              borderRadius="50"
              size="medium"
              style="width: 152px"
              v-preventReClick
              @click="handleAdd"
            >
              创建
            </vh-button>
          </noData>
        </div>
        <!-- 数据列表 -->
        <div class="vhc-main-content" v-if="!isEmpty">
          <vh-table
            ref="tableList"
            :data="dataList"
            v-loading="loading"
            @selection-change="handleSelectionChange"
            @cell-mouse-enter="handleCellMouseEnter"
            @cell-mouse-leave="handleCellMouseLeave"
            class="vhc-main-table"
          >
            <vh-table-column type="selection" width="60" fixed="left" :selectable="selectable" />
            <vh-table-column prop="title" label="标题" width="220">
              <template slot-scope="scope">
                <vh-tooltip placement="top" :disabled="!isTextOverflow" :content="scope.row.title">
                  <div class="custom-tooltip-content">
                    {{ scope.row.title }}
                  </div>
                </vh-tooltip>
              </template>
            </vh-table-column>
            <vh-table-column prop="survey_name" label="问卷名称">
              <template slot-scope="scope">
                <vh-tooltip
                  placement="top"
                  :disabled="!isTextOverflow"
                  :content="scope.row.survey_name"
                >
                  <div class="custom-tooltip-content">
                    {{ scope.row.survey_name || '-' }}
                  </div>
                </vh-tooltip>
              </template>
            </vh-table-column>
            <vh-table-column prop="award_name" label="礼品名称" width="120">
              <template slot-scope="scope">
                <vh-tooltip
                  placement="top"
                  :disabled="!isTextOverflow"
                  :content="scope.row.award_name"
                >
                  <div class="custom-tooltip-content">
                    {{ scope.row.award_name || '-' }}
                  </div>
                </vh-tooltip>
              </template>
            </vh-table-column>
            <vh-table-column prop="recipients_number" label="领奖人数" width="100">
              <template slot-scope="scope">
                <span v-if="scope.row.recipients_number < 1">
                  {{ scope.row.recipients_number }}
                </span>
                <vh-button v-else type="text" size="tiny" @click="handleUserList(scope.row)">
                  {{ scope.row.recipients_number }}
                </vh-button>
              </template>
            </vh-table-column>
            <vh-table-column label="操作" width="180" fixed="right">
              <template slot-scope="scope">
                <vh-button type="text" size="tiny" @click="handleCopy(scope.row)">复制</vh-button>
                <vh-button
                  type="text"
                  size="tiny"
                  :disabled="scope.row.recipients_number > 0"
                  @click="handleEdit(scope.row)"
                >
                  编辑
                </vh-button>
                <vh-button
                  type="text"
                  size="tiny"
                  :disabled="scope.row.recipients_number > 0"
                  @click="handleDelete(scope.row)"
                >
                  删除
                </vh-button>
              </template>
            </vh-table-column>
          </vh-table>
          <SPagination
            :total="total || 0"
            :page-size="pagination.limit"
            :currentPage="pagination.pageNo"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></SPagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import tableCellTooltip from '@/components/TableList/mixins/tableCellTooltip';
  import noData from '@/views/PlatformModule/Error/nullPage';
  import to from 'await-to-js';
  import { sessionOrLocal, isEmbed, embedRoomId } from '@/utils/utils';

  export default {
    name: 'InteractiveRewardTaskList',
    mixins: [tableCellTooltip],
    components: {
      noData
    },
    data() {
      return {
        isEmbed: isEmbed(),
        webinarId: '', //活动id

        isInited: false, // 是否有成功的请求
        loading: false,
        isEmpty: true, // 是否完全空数据

        total: 0,
        keyword: '',
        multipleSelection: [],
        dataList: [],
        // 分页参数
        pagination: {
          pos: 0,
          limit: 10,
          pageNo: 1
        }
      };
    },
    computed: {
      settingPath() {
        return `${isEmbed() ? '/embed' : ''}/live/InteractiveRewardSetting/${this.webinarId}`;
      },
      userListPath() {
        return `${isEmbed() ? '/embed' : ''}/live/InteractiveRewardUserList/${this.webinarId}`;
      }
    },
    methods: {
      // 创建
      handleAdd() {
        if (this.total >= 1000) {
          this.$vhMessage.info('最多可以创建1000个');
          return;
        }
        this.$router.push({
          path: this.settingPath,
          query: {
            roomId: embedRoomId() || this.$route.query.roomId
          }
        });
      },
      // 编辑某一条
      handleEdit(row) {
        this.$router.push({
          path: this.settingPath,
          query: {
            roomId: embedRoomId() || this.$route.query.roomId,
            irId: row.id
          }
        });
      },
      // 复制某一条
      handleCopy(row) {
        if (this.total >= 1000) {
          this.$vhMessage.info('最多可以创建1000个');
          return;
        }
        this.$router.push({
          path: this.settingPath,
          query: {
            roomId: embedRoomId() || this.$route.query.roomId,
            copy_id: row.id
          }
        });
      },
      // 领奖用户列表
      handleUserList(row) {
        this.$router.push({
          path: this.userListPath,
          query: {
            roomId: embedRoomId() || this.$route.query.roomId,
            id: row.id
          }
        });
      },
      // 删除某一条/批量删除
      async handleDelete(row) {
        const confirm = await this.$vhConfirm('删除后，当前内容无法使用，确认删除？', '提示', {
          roundButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'zdy-message-box',
          lockScroll: false,

          closeOnClickModal: false,
          closeOnPressEscape: false,
          cancelButtonClass: 'zdy-confirm-cancel'
        }).catch(() => false);
        if (!confirm) return;
        const params = {
          webinar_id: this.webinarId,
          interact_reward_ids: row
            ? row.id
            : (this.multipleSelection.map(item => item.id) || []).join(',')
        };
        const [err, res] = await to(this.$fetch('deleteInteractReward', params));
        if (err || res?.code != 200) {
          this.$vhMessage.error(err?.msg || res?.msg || '请求失败');
          return;
        }
        this.$vhMessage.success('删除成功');
        this.handleSearch();
      },

      // 搜索
      handleSearch() {
        this.pagination.pos = 0;
        this.fetchData();
      },
      // 全选/部分选中/取消/部分取消时触发
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      // 翻页事件
      handleCurrentChange(current) {
        this.pagination.pageNo = current; //当前页码
        this.pagination.pos = parseInt((current - 1) * this.pagination.limit);
        this.fetchData();
      },
      // 每页条数改变时触发
      handleSizeChange(limit) {
        this.pagination.limit = limit;
        this.pagination.pageNo = 1;
        this.pagination.pos = 0;
        this.fetchData();
      },

      // 获取互动有礼列表数据
      fetchData: async function () {
        this.loading = true;
        this.keyword = this.keyword.trim();
        const params = {
          webinar_id: this.webinarId,
          title: this.keyword,
          limit: this.pagination.limit,
          pos: this.pagination.pos
        };
        let [err, res] = await to(this.$fetch('getInteractRewardList', params));
        this.loading = false;
        if (err || res?.code != 200) {
          this.$vhMessage.error(err?.msg || res?.msg || '请求数据失败');
          return;
        }
        this.isInited = true;
        this.total = res.data.total || 0;
        this.isEmpty = !this.total && this.keyword.length === 0;
        this.dataList = res.data.list || [];
      },
      //复选框禁用逻辑处理
      selectable(row, rowIndex) {
        return !(row.recipients_number > 0);
      }
    },
    mounted() {
      this.webinarId = this.$route.params.str || '';
      this.fetchData();
    }
  };
</script>
